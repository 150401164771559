.slide {
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    
    .container {
      width: 1100px;
      position: relative;
    }
  
    .carousel-initialized {
      position: static;
    }
  
    .carousel-prev,
    .carousel-next {
      width: 50px;
      height: 50px;
      background-color: rgb(207, 196, 196);
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  
    .carousel-prev {
      left: -25px;
    }
  }