.featured {

    height: 600px;
    display: flex;
    justify-content: center;
    background-color: #013914;
    color: white;

    .container {
        width: 1100px;
        display: flex;
        align-items: center;

    }

    .left {
        display: flex;
        flex-direction: column;
        gap: 30px;

        h1 {
            font-size: 50px;

            i {
                font-weight: 300;
            }
        }

        .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            border-radius: 5px;

            .searchInput {
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                }

                input {
                    border: none;
                    outline: none;
                }
            }

            button {
                width: 120px;
                height: 50px;
                border: none;
                cursor: pointer;
                color: white;
                background-color: #1dbf73;
            }

        }

        .poular {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                color: white;
                background-color: transparent;
                border: 1px solid white;
                padding: 5px 10px;
                border-radius: 20px;
                font-size: 14px;
                cursor: pointer;
            }

        }
    }

    .right {
        height: 100%;

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}