.gig {
    display: flex;
    justify-content: center;
  
    .container {
      width: 1100px;
      padding: 30px 0px;
      display: flex;
      gap: 50px;
  
      .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        .breadcrumbs {
          font-weight: 300;
          text-transform: uppercase;
          font-size: 13px;
          color: #555;
        }
  
        h1 {
        }
  
        .user {
          display: flex;
          align-items: center;
          gap: 10px;
  
          .pp {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
          }
  
          span {
            font-size: 14px;
            font-weight: 500;
          }
  
          .stars {
            display: flex;
            align-items: center;
            gap: 5px;
  
            img {
              height: 14px;
              width: 14px;
            }
  
            span {
              font-size: 14px;
              font-weight: bold;
              color: #ffc108;
            }
          }
        }
  
        .slider {
          background-color: #F5F5F5;
          img {
            max-height: 500px;
            object-fit: contain;
          }
        }
  
        h2 {
          font-weight: 400;
        }
  
        p {
          font-weight: 300;
          line-height: 25px;
          color: #555;
        }
  
        .seller {
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          gap: 20px;
  
          .user {
            display: flex;
            align-items: center;
            gap: 20px;
  
            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
  
            .info {
              display: flex;
              flex-direction: column;
              gap: 10px;
  
              .stars {
                display: flex;
                align-items: center;
                gap: 5px;
  
                img {
                  height: 14px;
                  width: 14px;
                }
  
                span {
                  font-size: 14px;
                  font-weight: bold;
                  color: #ffc108;
                }
              }
  
              button {
                background-color: white;
                border-radius: 5px;
                border: 1px solid gray;
                padding: 10px;
              }
            }
          }
  
          .box {
            border: 1px solid lightgray;
            border-radius: 5px;
            padding: 20px;
            margin-top: 20px;
  
            .items {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
  
              .item {
                width: 300px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 20px;
  
                .title {
                  font-weight: 300;
                }
              }
            }
  
            hr {
              height: 0;
              border: 0.5px solid lightgray;
              margin-bottom: 20px;
            }
          }
        }
  
        .reviews {
          margin-top: 50px;
          .item {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 20px 0px;
            .user {
              display: flex;
              align-items: center;
              .pp {
                height: 50px;
                width: 50px;
                border-radius: 50%;
              }
  
              .country {
                display: flex;
                align-items: center;
                gap: 10px;
                color: gray;
  
                img {
                  width: 20px;
                }
              }
            }
  
            .stars {
              display: flex;
              gap: 5px;
  
              img {
                height: 14px;
                width: 14px;
              }
  
              span {
                font-size: 14px;
                font-weight: bold;
                color: #ffc108;
              }
            }
  
            .helpful {
              display: flex;
              align-items: center;
              gap: 10px;
  
              img {
                width: 14px;
              }
            }
          }
  
          hr {
            height: 0;
            border: 0.5px solid lightgray;
            margin: 50px 0px;
          }
        }
      }
  
      .right {
        flex: 1;
        border: 1px solid lightgray;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: max-content;
        max-height: 500px;
        position: sticky;
        top: 150px;
  
        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          h2{
            font-weight: 300;
          }
  
          h3{
            font-weight: 500;
          }
        }
  
        p{
          color: gray;
          margin: 10px 0px;
        }
  
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
  
          .item {
            display: flex;
            align-items: center;
            gap: 10px;
  
            img {
              width: 20px;
            }
          }
        }
  
        .features{
          .item{
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 300;
            color: gray;
            margin-bottom: 5px;
  
            img{
              width: 14px;
            }
          }
        }
  
        button{
          background-color: #1dbf73;
          padding: 10px;
          color: white;
          font-weight: 500;
          border: none;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }