.messages {
    display: flex;
    justify-content: center;
  
    .container {
      width: 1100px;
      padding: 50px 0px;
  
      .title {
        display: flex;
        justify-content: space-between;
  
        button {
          background-color: #1dbf73;
          color: white;
          font-weight: 500;
          border: none;
          padding: 10px;
          cursor: pointer;
        }
      }
  
      table {
        width: 100%;
  
        tr {
          height: 100px;
          th {
            text-align: left;
          }
          td {
            padding: 10px;
  
            &:first-child {
              font-weight: 500;
            }
            
            &:nth-child(2),
            &:nth-child(3) {
              color: gray;
            }
          }
  
          button {
            background-color: #1dbf73;
            color: white;
            padding: 10px;
            border: none;
            cursor: pointer;
          }
  
          &.active {
            background-color: #1dbf730f;
          }
        }
      }
    }
  }