.TrustedBy{
    background-color:#fafafa;
    display: flex;
    height: 100px;
    justify-content: center;


    .container {
        width: 760px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        color: lightgray;
        font-weight: 500;

        img{
            height: 50px;
            object-fit: contain;
        }
    }
}