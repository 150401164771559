.orders {
    display: flex;
    justify-content: center;
    color: #555;
  
    .container {
      width: 1100px;
      padding: 50px 0px;
  
      .title {
        display: flex;
        justify-content: space-between;
  
        button {
          background-color: #1dbf73;
          color: white;
          font-weight: 500;
          border: none;
          padding: 10px;
          cursor: pointer;
        }
      }
  
      table {
        width: 100%;
  
        tr {
          height: 50px;
          th {
            text-align: left;
          }
          td {
            
            sup{
              font-size: 12px;
            }
  
            .image {
              width: 50px;
              height: 25px;
              object-fit: cover;
            }
  
            .message {
              width: 25px;
              cursor: pointer;
            }
          }
          &:nth-child(even) {
            background-color: #1dbf730f;
          }
        }
      }
    }
  }