.gigCard {
    width: 324px;
    height: 400px;
    // border: 1px solid rgb(237, 236, 236);
    border: 1px solid rgb(228, 228, 228);
    margin-bottom: 40px;
  
    img {
      width: 100%;
      height: 50%;
      object-fit: cover;
    }
  
    .info {
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .user {
        display: flex;
        align-items: center;
        gap: 10px;
  
        img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          object-fit: cover;
        }
  
        span {
        }
      }
  
      p {
        color: #111;
      }
  
      .star {
        display: flex;
        align-items: center;
        gap: 5px;
  
        img {
          height: 14px;
          width: 14px;
        }
  
        span {
          font-size: 14px;
          font-weight: bold;
          color: #ffc108;
        }
      }
    }
  
    hr {
      height: 0;
      border: 0.5px solid rgb(228, 228, 228);
    }
  
    .detail {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
        object-fit: cover;
      }
  
      .price {
        span {
          color: #999;
          font-size: 12px;
        }
  
        h2 {
          color: #555;
          font-size: 18px;
          font-weight: 400;
          text-align: end;
        }
  
        sup {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }