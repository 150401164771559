.CategoryCard{
    width: 252px;
    height: 344;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    img{
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    .desc {
        font-weight: 300;
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .title{
        font-weight: 500;
        font-size: 24px;
        position: absolute;
        top: 15px;
        left: 40px;
    }
}