.footer {
    display: flex;
    justify-content: center;
    color: #666;
    margin: 10px 0px;
  
    img {
      width: 24px;
      height: 24px;
    }
  
    .container {
      width: 1100px;
      .top {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          flex-direction: column;
          gap: 20px;
          h2 {
            font-size: 16px;
            color: #555;
          }
          span {
            font-weight: 300;
          }
        }
      }
  
      hr {
        margin: 20px 0px;
        height: 0;
        border: 1px solid #ebe9e9;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .left {
          width: max-content;
          display: flex;
          align-items: center;
          gap: 20px;
  
          span {
            font-size: 13px;
            white-space: nowrap;
          }
        }
        .right {
          width: max-content;
          display: flex;
          align-items: center;
          gap: 30px;
  
          .social {
            display: flex;
            gap: 20px;
          }
  
          .link {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }