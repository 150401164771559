.home {
    .features {
      background-color: #f1fdf7;
      display: flex;
      justify-content: center;
      padding: 20px 0px;
  
      &.dark {
        background-color: #0d084d;
  
        h1 {
          color: white !important;
        }
  
        i {
          font-weight: 300;
        }
  
        p {
          margin-bottom: 20px;
          color: white !important;
        }
  
        .title {
          color: white !important;
          font-weight: 300 !important;
          font-size: 14px !important;
        }
  
        button {
          background-color: #1dbf73;
          border: none;
          color: white;
          padding: 10px 20px;
          border-radius: 5px;
          width: max-content;
          font-size: 16px;
          cursor: pointer;
          margin-top: 20px;
        }
      }
  
      .container {
        width: 1400px;
        display: flex;
        align-items: center;
        gap: 200px;
        .item {
          display: flex;
          flex-direction: column;
          gap: 15px;

          &:first-child {
            flex: 2;
          }
          &:last-child {
            flex: 3;
          }
  
          img{
            width: 100%;
          }
          h1 {
            font-weight: 500;
            margin-bottom: 10px;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            font-size: 18px;
            color: #666;
            img {
              width: 24px;
              height: 24px;
            }
          }
          p {
            font-size: 18px;
            font-weight: 300;
            color: gray;
            line-height: 28px;
            letter-spacing: 1px;
          }
  
          video{
            width: 720px;
            height: 400px;
          }
        }
      }
    }
  
    .explore {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
  
      .container {
        width: 1100px;
  
        h1{
          color: #555;
        }
  
        .items {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .item {
            width: 250px;
            height: 150px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
  
            img {
              width: 50px;
              height: 50px;
            }
  
            .line{
              width: 50px;
              height: 2px;
              background-color: lightgray;
              transition: all .3s ease;
            }
  
            span {
              font-weight: 300;
            }
  
            &:hover{
              .line{
                width: 80px;
                background-color: #1dbf73;
              }
            }
          }
        }
      }
    }
  }